import { pubsub } from './pubsub';
import { googleConsents } from './consents';
import cookie_consent from './templates/cookie_consent.pug';

export const cookiesByCategory = (cookies, category) => {
  return cookies.filter((cookie) => cookie.category === category);
};

export const renderDialog = (dialogId, consents, settings) => {
  const html = cookie_consent({
    wrapperId: dialogId,
    consents,
    cookies: {
      necessary: cookiesByCategory(settings.cookies, 'necessary'),
      preferences: cookiesByCategory(settings.cookies, 'preferences'),
      analytics: cookiesByCategory(settings.cookies, 'analytics'),
      marketing: cookiesByCategory(settings.cookies, 'marketing'),
    },
    content: settings.content,
  });
  document.body.insertAdjacentHTML('beforeend', html);
  return document.querySelector(`#${dialogId}`);
};

export const onTabClick = (e) => {
  e.preventDefault();
  const tabPanels = Array.from(
    document.querySelectorAll('.cc-wrapper [role="tabpanel"]')
  );
  const tabs = Array.from(document.querySelectorAll('.cc-menu [role="tab"]'));

  const targetPanelId = e.target.getAttribute('aria-controls');
  const tabPanel = document.querySelector(`#${targetPanelId}`);

  tabs.forEach((el) => el.classList.remove('cc-menu__item--active'));
  e.target.classList.add('cc-menu__item--active');

  tabPanels.forEach((el) => el.classList.add('cc-hidden'));
  tabPanel.classList.remove('cc-hidden');

  if (targetPanelId === 'cc_panel_details') {
    document
      .querySelector('.cc-footer [data-cc-action="customize"]')
      .classList.add('cc-hidden');
    document
      .querySelector('.cc-footer [data-cc-action="allow_selection"]')
      .classList.remove('cc-hidden');
  } else {
    document
      .querySelector('.cc-footer [data-cc-action="customize"]')
      .classList.remove('cc-hidden');
    document
      .querySelector('.cc-footer [data-cc-action="allow_selection"]')
      .classList.add('cc-hidden');
  }
};

export const onCheckboxChange = (e) => {
  const action = e.target.checked ? 'add' : 'remove';
  pubsub.publish(`cookieconsent.${action}`, {
    consent: e.target.value,
  });
};

export const onActionClick = (e) => {
  e.preventDefault();
  const action = e.target.getAttribute('data-cc-action');
  if (action === 'customize') {
    document.querySelector('#cc_menu_item_details').click();
    return;
  }
  pubsub.publish('cookieconsent.action', {
    action,
  });
};

export const updateCheckboxes = (consents, parentEl) => {
  parentEl
    .querySelectorAll('input[type="checkbox"]')
    .forEach((cbEl) => (cbEl.checked = false));

  consents.forEach((consent) => {
    const cbEl = parentEl.querySelector(
      `input[type="checkbox"][value="${consent}"]`
    );
    if (cbEl) {
      cbEl.checked = true;
    }
  });
};

export const saveConsent = (consents) => {
  return new Promise((resolve, reject) => {
    const googleUpdateData = {};
    googleConsents.forEach((c) => {
      googleUpdateData[c.name] = consents.includes(c.category)
        ? 'granted'
        : 'denied';
    });

    if (typeof window.gtag !== 'undefined') {
      window.gtag('consent', 'update', googleUpdateData);
    }

    if (typeof window.fbq !== 'undefined') {
      if (consents.includes('marketing')) {
        window.fbq('consent', 'grant');
      } else {
        window.fbq('consent', 'revoke');
      }
    }

    resolve();
  });
};

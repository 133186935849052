export const googleConsents = [
  {
    name: 'security_storage',
    category: 'necessary',
  },
  {
    name: 'functionality_storage',
    category: 'preferences',
  },
  {
    name: 'personalization_storage',
    category: 'preferences',
  },
  {
    name: 'analytics_storage',
    category: 'analytics',
  },
  {
    name: 'ad_storage',
    category: 'marketing',
  },
  {
    name: 'ad_user_data',
    category: 'marketing',
  },
  {
    name: 'ad_personalization',
    category: 'marketing',
  },
];

jQuery.fn.extend({
  ajaxValidate: function() {
      return this.each(function() {
          $(this).submit(function(event){
              if($(this).data("validated")!="yes"){
                  event.preventDefault();
                  return ajaxValidate($(this));
              }
          })
      });
  },
  disableSubmit: function(){
      return this.each(function(){
          $(this).prop("disabled",true).attr("data-origval",$(this).html()).html("Egy pillanat...");
      })
  },
  enableSubmit: function(){
      return this.each(function(){
          $(this).prop("disabled",false).html($(this).attr("data-origval"));
      })
  },
  clearErrors: function(){
      return this.each(function(){
          // $(this).find(".input_error_label").remove();
          $(this).find(".alert-danger").remove();
          // $(this).find(".input_error_field").removeClass("input_error_field");
          $(this).find(".is-invalid").each(function() {
              $(this).removeClass("is-invalid");
              $(this)[0].removeAttribute("aria-describedby");
          });
      })
  },
  showErrors: function(errors){
      return this.each(function(){
          for(i in errors){
              input = $(this).find("[name='"+i+"']");
              if(input.length<1)
                  modalError("Hiányzó mező: "+i);

              // input.addClass("input_error_field");
              input.addClass('is-invalid');
              input[0].setAttribute('aria-describedby', 'field_error_'+i);

              // label_error = "<label class='input_error_label' data-field='"+i+"'>"+errors[i]+"</label>";
              label_error = '<div class="alert alert-danger small px-2 py-2 mt-1" data-field="'+i+'" id="field_error_'+i+'">'+errors[i]+'</div>';

              if(input.data("error-after"))
                  $(input.data("error-after")).after(label_error)
              else
                  input.after(label_error)
          }
      })
  },
});

function ajaxValidate(form){
  form.find("[type='submit']").disableSubmit()
  form.clearErrors();

  loadRecaptcha(function(){
      $.ajax({
          url: form.attr("action"),
          data: new FormData(form[0]),
          type: "POST",
          dataType: "json",
          processData: false,
          contentType: false,
          headers: {onlyvalidate:((form.data("validate")=="submit")?"yes":"no")},
          success: function(response){
              if(response.csrf_token)
                  $("[name='csrf_token']").val(response.csrf_token);

              if(response.status=="success"){
                  if(form.data("validate")=="submit")
                      form.data("validated","yes").submit()
                  else if(form.data("validate")=="redirect")
                      window.location(response.redirect)
                  else {
                      modalError(response.message);
                      form.find("[type='submit']").enableSubmit()

                      if(form.data("validate")=="reset")
                          form.trigger("reset");
                  }
              }
              else {
                  form.find("[type='submit']").enableSubmit()
                  if(response.message.fields) {
                      form.showErrors(response.message.fields)
                  }
                  else if(response.message) {
                      modalError(response.message);
                  }
                  else {
                      modalError("Ismeretlen hiba történt");
                  }
              }
          },
          error: function(response){
              form.find("[type='submit']").enableSubmit()
              modalError("Ismeretlen hiba történt!");
          }
      });
  })

  return false;
}

function loadRecaptcha(callback){
  if(typeof(recaptcha_site_key)!="undefined"){
      grecaptcha.ready(function () {
          grecaptcha.execute(recaptcha_site_key, { action: 'contact' }).then(function (response) {
              $("[name='g-recaptcha-response']").val(response);
              callback();
          });
      });
  }
  else
      callback();
}

import { onTabClick, onCheckboxChange, onActionClick } from './handlers';

export const addEventListeners = () => {
  Array.from(document.querySelectorAll('.cc-body [role="tab"]')).forEach(
    (btnEl) => {
      btnEl.addEventListener('click', onTabClick);
    }
  );
  Array.from(document.querySelectorAll('.cc-footer [data-cc-action]')).forEach(
    (btnEl) => {
      btnEl.addEventListener('click', onActionClick);
    }
  );
  Array.from(document.querySelectorAll('.cc-switch input')).forEach((cbEl) => {
    cbEl.addEventListener('input', onCheckboxChange);
  });
};

export const removeEventListeners = () => {
  Array.from(document.querySelectorAll('.cc-body [role="tab"]')).forEach(
    (btnEl) => {
      btnEl.removeEventListener('click', onTabClick);
    }
  );
  Array.from(document.querySelectorAll('.cc-footer [data-cc-action]')).forEach(
    (btnEl) => {
      btnEl.removeEventListener('click', onActionClick);
    }
  );
  Array.from(document.querySelectorAll('.cc-switch input')).forEach((cbEl) => {
    cbEl.removeEventListener('input', onCheckboxChange);
  });
};

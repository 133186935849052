import Cookies from 'js-cookie';
import { pubsub } from '../cms/cookie_consent/pubsub';
import { addEventListeners, removeEventListeners } from '../cms/cookie_consent/listeners';
import { renderDialog, updateCheckboxes, saveConsent } from '../cms/cookie_consent/handlers';

let config = {
  settings: {
    remember_cookie_name: 'cc_consent',
    remember_cookie_expire: 365,
    trigger: '[data-cc-open]',
  },
  cookies: [],
  content: {},
};

let ccWrapperId = `cc-wrapper-${new Date().getTime()}`;
let ccWrapperEl = null;

window.addEventListener('DOMContentLoaded', () => {
  /**
   * Cookie consent configuration
   */
  if (typeof window.simple_cookie_consent_config === 'undefined') {
    throw new Error('Invalid cookie consent configuration.');
  }

  // set default content
  let lang = 'hu';
  if (
    typeof window.simple_cookie_consent_config.settings !== 'undefined' &&
    typeof window.simple_cookie_consent_config.settings.lang !== 'undefined'
  ) {
    lang = window.simple_cookie_consent_config.settings.lang;
  }
  config.content = {
    "intro_title": "Ez a weboldal sütiket használ",
    "intro_description": "A kényelmes használat érdekében, a tartalom és a közösségi funkciók biztosításához, a weboldal forgalmunk elemzéséhez és reklámozás céljából sütiket használunk. A sütikkel kapcsolatos beállításaidat a későbbiekben bármikor módosíthatod a láblécben található \"Süti beállítások\" linkre kattintva.",
    "about_description": "<p>A cookie-k kis szöveges fájlok, amelyeket a webhelyek felhasználhatnak a felhasználói élmény hatékonyabbá tételére.</p><p>A törvény kimondja, hogy cookie-kat tárolhatunk az Ön eszközén, ha azok feltétlenül szükségesek az oldal működéséhez. Minden más típusú sütihez az Ön engedélyére van szükségünk. Ez azt jelenti, hogy a szükségesnek minősített cookie-k feldolgozása a GDPR Art. alapján történik. 6. (1) bekezdés f) pontja. Az összes többi cookie, azaz a kategóriák preferenciáiból és marketingjéből származó cookie-k feldolgozása a GDPR cikke alapján történik. 6. (1) bekezdés a) pont GDPR.</p><p>Ez az oldal különböző típusú sütiket használ. Egyes sütiket harmadik fél szolgáltatásai helyeznek el, amelyek megjelennek oldalainkon.</p><p>Tudjon meg többet arról, hogy kik vagyunk, hogyan léphet kapcsolatba velünk, és hogyan dolgozzuk fel a személyes adatokat az <a href=\"#\" target=\"_blank\">Adatvédelmi szabályzatunkban</a>.</p>",

    "menu_item_intro": "Beleegyezés",
    "menu_item_customize": "Részletek",
    "menu_item_about": "Információk",

    "action_deny_label": "Elutasítás",
    "action_customize_label": "Testreszabás",
    "action_allow_all_label": "Összes engedélyezése",
    "action_allow_selection_label": "Kijelöltek engedélyezése",

    "group_necessary_label": "Elengedhetetlen",
    "group_necessary_description": "Ezen sütik célja a honlapunk használata, valamint használatáról való információszerzés: Munkamenet (session) sütik, szűkített funkcionalitású Google Analytics, használatot elősegítő sütik.",
    "group_preferences_label": "Preferenciák",
    "group_preferences_description": "Ezen sütik lehetővé teszik a webhely számára, hogy megjegyezze azokat az információkat, amelyek megváltoztatják a webhely viselkedését vagy megjelenését.",
    "group_analytics_label": "Statisztika",
    "group_analytics_description": "Ezen sütik célja a honlapunk használatáról való információszerzés, a weboldalon végzett tevékenység nyomon követése: Google Analytics.",
    "group_marketing_label": "Marketing",
    "group_marketing_description": "Ezen sütik célja a honlapunk használatáról való információszerzés, a weboldal használóinak megkülönböztetése, a weboldalon végzett tevékenység nyomon követése, célzott hirdetések elhelyezése: Google Analytics, Google Adwords, Facebook konverziós kód, Facebook remarketing pixel."
  };

  if (typeof window.simple_cookie_consent_config.settings !== 'undefined') {
    // merge settings
    config.settings = {
      ...config.settings,
      ...window.simple_cookie_consent_config.settings,
    };
  }

  if (typeof window.simple_cookie_consent_config.cookies !== 'undefined') {
    // set cookie list
    config.cookies = window.simple_cookie_consent_config.cookies;
  }

  if (typeof window.simple_cookie_consent_config.content !== 'undefined') {
    // merge content
    config.content = {
      ...config.content,
      ...window.simple_cookie_consent_config.content,
    };
  }

  // get stored consents
  let consents = Cookies.get(config.settings.remember_cookie_name)
    ? JSON.parse(Cookies.get(config.settings.remember_cookie_name))
    : ['necessary'];

  // auto save (update) based on stored consents
  saveConsent(consents);

  pubsub.subscribe('cookieconsent.add', ({ consent }) => {
    if (!consents.includes(consent)) {
      consents.push(consent);
      updateCheckboxes(consents, ccWrapperEl);
    }
  });

  pubsub.subscribe('cookieconsent.remove', ({ consent }) => {
    if (consents.includes(consent)) {
      consents.splice(consents.indexOf(consent), 1);
      updateCheckboxes(consents, ccWrapperEl);
    }
  });

  pubsub.subscribe('cookieconsent.action', ({ action }) => {
    if (action === 'deny') {
      consents = ['necessary'];
    } else if (action === 'allow_all') {
      consents = ['necessary', 'preferences', 'analytics', 'marketing'];
    }
    updateCheckboxes(consents, ccWrapperEl);

    saveConsent(consents).then(() => {
      // store consents
      Cookies.set(
        config.settings.remember_cookie_name,
        JSON.stringify(consents),
        { expires: config.settings.remember_cookie_expire }
      );
      // remove dialog
      removeEventListeners();
      ccWrapperEl.remove();
      document.body.style.overflowY = '';
    });
  });

  if (!Cookies.get(config.settings.remember_cookie_name)) {
    setTimeout(() => {
      ccWrapperEl = renderDialog(ccWrapperId, consents, config);
      document.body.style.overflowY = 'hidden';
      addEventListeners();
    }, 2000);
  }

  // manually open consent
  Array.from(document.querySelectorAll(config.settings.trigger)).forEach(
    (triggerEl) => {
      triggerEl.addEventListener('click', (e) => {
        e.preventDefault();
        ccWrapperEl = renderDialog(ccWrapperId, consents, config);
        document.body.style.overflowY = 'hidden';
        addEventListeners();
      });
    }
  );
});